import React from "react";
import ReactPlayer from "react-player/vimeo";
import { Box } from "@mui/material";

const VimeoVideo = ({ url, ...props }) => {
  console.log(url); // To verify the URL is correct
  return (
    <Box
      className="vimeo-video"
      sx={{ position: "relative", paddingTop: "56.25%", maxWidth: "82%" }}
    >
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        style={{ position: "absolute", top: 0, left: 0 }}
        controls
        {...props}
      />
    </Box>
  );
};

export default VimeoVideo;
