import React, { useContext } from "react";
import { IsMobileContext } from "layouts";
import { IconButton } from "@mui/material";
import ComputerIcon from "@mui/icons-material/Computer";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

export default function ViewButton() {
  const { isMobile, toggleIsMobile } = useContext(IsMobileContext);
  const title = isMobile ? "Desktop view" : "Mobile view";

    
  if (typeof isMobile === "boolean") {
    return (
      <IconButton
        sx={{ ml: 1 }}
        onClick={toggleIsMobile}
        color="inherit"
        title={title}
      >
        {isMobile ? <ComputerIcon /> : <SmartphoneIcon />}
      </IconButton>
    );
  }
  return <></>;
}
