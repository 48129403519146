import React from "react";
import slugify from "@sindresorhus/slugify";
import { Typography } from "@mui/material";

function addLinebreaks(children) {
  /** Push <br/> items instead of "\n" tags */

  let childrenWithLinebreaks = [];
  children.forEach((child) => {
    if (typeof child === "string" && child.includes("\n")) {
      const splittedChild = child.split("\n");

      const childWithLinebreaks = splittedChild.map((str, i) => {
        if (i !== splittedChild.length - 1) {
          return [str, <br key={str} />].flat();
        } else {
          return str;
        }
      });

      childrenWithLinebreaks =
        childrenWithLinebreaks.concat(childWithLinebreaks);
    } else {
      childrenWithLinebreaks.push(child);
    }
  });
  return childrenWithLinebreaks;
}

function paragraph(node, children) {
  children = addLinebreaks(children);

  if (typeof children[0] == "string") {
    // Breaklines
    if (children.length === 1 && children[0].replace(" ", "") === "") {
      return <br />;
    }

    //const isLink =
    //  children.some((item) => item.hasOwnProperty("props")) &&
    //  children[0] === "";
    //const flex = isLink ? "flex" : "";
    //const gap = isLink ? "1rem" : "";

    // Text
    return (
      <Typography
        as="p"
        id={slugify(children[0].toLowerCase())}
        sx={{
          marginBottom: "1rem",
        //  display: flex,
        //  gap: gap,
          overflowWrap: "break-word",
        }}
      >
        {children}
      </Typography>
    );
  }

  // TODO: fix hardcoded "id"
  let id = children[0].props?.children
    ? slugify(children[0].props.children.toLowerCase())
    : "id";

  return (
    <Typography as="p" id={id}>
      {children}
    </Typography>
  );
}

export default paragraph;
