import React, { useState, useContext } from "react";
import { useLocation } from "@reach/router";
import { Button, Box, Menu, MenuItem, Typography } from "@mui/material";
import { Link } from "components";
import { IsMobileContext, ColorModeContext } from "layouts";

export default function LanguageSelector({ lang }) {
  const { mode } = useContext(ColorModeContext);
  const { isMobile } = useContext(IsMobileContext);
  const location = useLocation();

  const langList = [];
  const enPath = location.pathname.replace(`/${lang}/`, "/");
  switch (lang) {
    case "ua":
      langList.push(
        {
          code: "En",
          title: "English",
          path: enPath,
          active: false,
        },
        {
          code: "Ua",
          title: "Українська",
          path: location.pathname.replace(`/${lang}/`, "/ua/"),
          active: true,
        },
        {
          code: "Ru",
          title: "Русский",
          path: location.pathname.replace(`/${lang}/`, "/ru/"),
          active: false,
        }
      );
      break;

    case "ru":
      langList.push(
        {
          code: "En",
          title: "English",
          path: enPath,
          active: false,
        },
        {
          code: "Ua",
          title: "Українська",
          path: location.pathname.replace(`/${lang}/`, "/ua/"),
          active: false,
        },
        {
          code: "Ru",
          title: "Русский",
          path: location.pathname.replace(`/${lang}/`, "/ru/"),
          active: true,
        }
      );
      break;

    default: // en
      langList.push(
        {
          code: "En",
          title: "English",
          path: enPath,
          active: true,
        },
        {
          code: "Ua",
          title: "Українська",
          path: `/ua${location.pathname}`,
          active: false,
        },
        {
          code: "Ru",
          title: "Русский",
          path: `/ru${location.pathname}`,
          active: false,
        }
      );
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl((prevAnchorEl) => event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  if (typeof isMobile == "boolean") {
    return (
      <Box>
        {isMobile ? (
          <>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{ width: "4rem" }}
            >
              {lang}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{ mt: "0.5rem" }}
            >
              {langList
                .filter((lang) => !lang.active)
                .map((lang) => (
                  <Link to={lang.path} width="100%" key={lang.code}>
                    <MenuItem
                      onClick={handleClose}
                      sx={{ width: "4rem", textAlign: "center" }}
                    >
                      <Typography as="p" m="auto">
                        {lang.code}
                      </Typography>
                    </MenuItem>
                  </Link>
                ))}
            </Menu>
          </>
        ) : (
          <>
            {langList.map((lang) => (
              <Link to={lang.path} key={lang.code}>
                <Button
                  title={lang.title}
                  variant={lang.active === true ? "outlined" : ""}
                  sx={
                    mode === "light" && lang.active === true
                      ? {
                          backgroundColor: "rgba(255, 255, 255, 0.2)",
                          color: "white",
                          border: "none",
                          ":hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            border: "none",
                          },
                        }
                      : {}
                  }
                >
                  {lang.code}
                </Button>
              </Link>
            ))}
          </>
        )}
      </Box>
    );
  }
}
