import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
// import { enUS, ukUA, ruRU } from "@mui/material/locale";
import { getComponents, getDesignTokens, breakpoints } from "styles";

export const ColorModeContext = React.createContext({
  //  Dummy func to fix "WebpackError: TypeError: Cannot destructure property 'mode'"
  toggleColorMode: () => {},
});

export const ColorModeContextProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  let mode = prefersDarkMode ? "dark" : "light";

  if (typeof window !== "undefined" && window.localStorage) {
    mode = localStorage.getItem("colorMode") || mode;
  }

  // TODO: refactor it (do we need to use useState here?)
  const [isDarkMode, setIsDarkMode] = React.useState(mode === "dark");
  // console.log("mode", mode);
  // console.log("isDarkMode", isDarkMode);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setIsDarkMode((prev) => !prev);
        const newMode = isDarkMode ? "light" : "dark";

        if (typeof window !== "undefined" && window.localStorage) {
          localStorage.setItem("colorMode", newMode); // Save the mode to local storage
        }
        // console.log(">> newMode", newMode);
      },
    }),
    [isDarkMode]
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: getDesignTokens(mode),
        components: getComponents(mode),
        breakpoints,
        // enUS,
        // ukUA,
        // ruRU,
      }),
    [mode]
  );

  return (
    <React.StrictMode>
      <ColorModeContext.Provider value={{ colorMode, mode }}>
        <ThemeProvider theme={theme}>
          {/* <CssBaseline enableColorScheme /> */}
          <CssBaseline />
          {children}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </React.StrictMode>
  );
};
