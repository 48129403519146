import React from "react";
import { Link } from "components";
import ServiceLink from "./ServiceLink";
import YouTubeVideo from "./YouTubeVideo";
import VimeoVideo from "./VimeoVideo";
import MoreDetailsLink from "../MoreDetailsLink";

function getServiceLink(url) {
  const serviceMap = [
    {
      keyword: "android:",
      service: "androidApk",
      title: "Android APK",
      processUrl: (url) => url.replace("android:", ""),
    },
    { keyword: "youtube.com", service: "youtube", title: "YouTube" },
    { keyword: "steam.com", service: "steam", title: "Steam" },
    { keyword: "steampowered.com", service: "steam", title: "Steam" },
    { keyword: "itch.io", service: "itch", title: "Itch.io" },
    { keyword: "github.com", service: "github", title: "GitHub" },
    {
      keyword: "drive.google.com",
      service: "googleDrive",
      title: "Google Drive",
    },
    { keyword: "onstove.com", service: "stove", title: "STOVE" },
    {
      keyword: "emscripten:",
      service: "emscripten",
      title: "Emscripten",
      processUrl: (url) => url.replace("emscripten:", ""),
    },
  ];

  // Find the matching service for the given URL
  for (let item of serviceMap) {
    if (url.indexOf(item.keyword) !== -1) {
      const processedUrl = item.processUrl ? item.processUrl(url) : url;
      return (
        <ServiceLink
          uri={processedUrl}
          service={item.service}
          title={item.title}
        />
      );
    }
  }
}

function getWidget(url) {
  // TODO: set general logic, not only YouTube

  if (url.indexOf("youtube.com") !== -1) {
    return <YouTubeVideo url={url} />;
  }

  if (url.indexOf("vimeo.com") !== -1) {
    return <VimeoVideo url={url} />;
  }
}

function hyperlink(node) {
  const uri = node.data.uri;

  if (uri.includes("icon:")) {
    const url = uri.replace("icon:", "");
    return getServiceLink(url);
  } else if (uri.indexOf("widget:") !== -1) {
    const url = uri.replace("widget:", "");
    return getWidget(url);
  }

  const text = node.content[0].value;

  if (text === "[More details]") {
    return <MoreDetailsLink path={uri} lang="en" />;
  }
  if (text === "[Детальніше]") {
    return <MoreDetailsLink path={uri} lang="ua" />;
  }
  if (text === "[Подробнее]") {
    return <MoreDetailsLink path={uri} lang="ru" />;
  }

  return (
    <Link to={uri} style={{ textDecoration: "underline", whiteSpace: "wrap" }}>
      {text}
    </Link>
  );
}

export default hyperlink;
