import * as React from "react";
import MuiLink from "@mui/material/Link";
import { Link as GatsbyLink } from "gatsby";

const Link = React.forwardRef(function Link(props, ref) {
  // Check if the destination is an external link and does not belong to your domain
  const url = props.to;
  const isExternalLink =
    typeof url === "string" &&
    url.startsWith("https://") &&
    !url.includes("tinaynox.com");

  if (isExternalLink) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit", ...props.style }}
      >
        {props.children}
      </a>
    );
  }

  return (
    <MuiLink
      component={GatsbyLink}
      ref={ref}
      color="inherit"
      style={{ textDecoration: "none" }}
      {...props}
    />
  );
});

export default Link;
