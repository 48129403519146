import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  createContext,
} from "react";

export const IsMobileContext = createContext();

function isSmallScreen() {
  return (
    window.innerWidth <= 650 ||
    (window.innerHeight <= 400 && window.innerWidth <= 768)
  );
}

export const IsMobileContextProvider = ({ children }) => {
  // const [isMobile, setIsMobile] = React.useState(useIsMobile());

  const [isMobile, setIsMobile] = useState();

  const toggleIsMobile = useMemo(
    () => () => {
      setIsMobile((prev) => !prev);
    },
    []
  );

  useEffect(() => {
    // if (typeof window !== "undefined") {
    const mobileView = isSmallScreen();

    setIsMobile(mobileView);

    const handleResize = () => {
      setIsMobile(isSmallScreen());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // }
  }, []);

  // console.log("isMobile IsMobileContextProvider", isMobile);

  return (
    <IsMobileContext.Provider value={{ isMobile, toggleIsMobile }}>
      {children}
    </IsMobileContext.Provider>
  );
};

export const useIsMobile = () => {
  const context = useContext(IsMobileContext);
  if (!context) {
    throw new Error("useIsMobile must be used within an IsMobileProvider");
  }
  return context;
};
