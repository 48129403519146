import React from "react";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

export default function Code({ children, ...props }) {
  const theme = useTheme();
  const colorMode = theme.palette.mode;

  const styles =
    colorMode === "light"
      ? {
          backgroundColor: grey[100],
        }
      : {
          backgroundColor: grey[900],
        };

  return (
    <Box
      variant="code"
      sx={{
        padding: "0.75rem",
        borderRadius: "0.25rem",
        mb: "0.5rem",
        whiteSpace: "pre-wrap",
        ...styles,
        ...props,
      }}
    >
      <Box as="code">{children}</Box>
    </Box>
  );
}
