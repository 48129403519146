import "@fontsource/roboto";
import "@mui/icons-material";
import {
  ColorModeContextProvider,
  LangContextProvider,
  IsMobileContextProvider,
//  ScrollReset,
} from "layouts";
import { LocationProvider } from "@reach/router";
import { Layout } from "layouts";
import React from "react";
// import { Layout } from "./src/components/Layouts";

export const wrapRootElement = ({ element, props }) => {
  return (
    <IsMobileContextProvider>
      <LocationProvider>
        <LangContextProvider>
          <ColorModeContextProvider>
            {/* <ScrollReset> */}
            <Layout {...props}>{element}</Layout>
            {/* </ScrollReset> */}
          </ColorModeContextProvider>
        </LangContextProvider>
      </LocationProvider>
    </IsMobileContextProvider>
  );
};
