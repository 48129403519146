import * as React from "react";
import { Typography, Breadcrumbs } from "@mui/material";
import { Link } from "components";

export default function CustomBreadcrumbs({ lang, levels, ...props }) {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        marginTop: "0.25rem",
        fontStyle: "italic",
        minHeight: "2.5rem",
        display: "flex",
        alignItems: "center",
        ...props,
      }}
    >
      {levels.map((level, i) => {
        let { text, url, isHeading } = level;

        if (lang && lang !== "en") {
          url = "/" + lang + url;
        }

        return (
          <Link key={url} to={url}>
            <Typography
              fontSize={{ xs: "18px", md: "16px" }}
              lineHeight={{ xs: "30px", md: "inherit" }}
              color={i === levels.length - 1 ? "text.primary" : "inherit"}
              as={isHeading ? "h1" : "span"}
            >
              {text}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
