exports.components = {
  "component---src-page-templates-app-data-js": () => import("./../../../src/pageTemplates/appData.js" /* webpackChunkName: "component---src-page-templates-app-data-js" */),
  "component---src-page-templates-article-js": () => import("./../../../src/pageTemplates/article.js" /* webpackChunkName: "component---src-page-templates-article-js" */),
  "component---src-page-templates-document-js": () => import("./../../../src/pageTemplates/document.js" /* webpackChunkName: "component---src-page-templates-document-js" */),
  "component---src-page-templates-project-js": () => import("./../../../src/pageTemplates/project.js" /* webpackChunkName: "component---src-page-templates-project-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-index-en-js": () => import("./../../../src/pages/articles/index.en.js" /* webpackChunkName: "component---src-pages-articles-index-en-js" */),
  "component---src-pages-articles-index-ru-js": () => import("./../../../src/pages/articles/index.ru.js" /* webpackChunkName: "component---src-pages-articles-index-ru-js" */),
  "component---src-pages-articles-index-ua-js": () => import("./../../../src/pages/articles/index.ua.js" /* webpackChunkName: "component---src-pages-articles-index-ua-js" */),
  "component---src-pages-contacts-index-en-js": () => import("./../../../src/pages/contacts/index.en.js" /* webpackChunkName: "component---src-pages-contacts-index-en-js" */),
  "component---src-pages-contacts-index-ru-js": () => import("./../../../src/pages/contacts/index.ru.js" /* webpackChunkName: "component---src-pages-contacts-index-ru-js" */),
  "component---src-pages-contacts-index-ua-js": () => import("./../../../src/pages/contacts/index.ua.js" /* webpackChunkName: "component---src-pages-contacts-index-ua-js" */),
  "component---src-pages-documents-index-en-js": () => import("./../../../src/pages/documents/index.en.js" /* webpackChunkName: "component---src-pages-documents-index-en-js" */),
  "component---src-pages-documents-index-ru-js": () => import("./../../../src/pages/documents/index.ru.js" /* webpackChunkName: "component---src-pages-documents-index-ru-js" */),
  "component---src-pages-documents-index-ua-js": () => import("./../../../src/pages/documents/index.ua.js" /* webpackChunkName: "component---src-pages-documents-index-ua-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ru-js": () => import("./../../../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-index-ua-js": () => import("./../../../src/pages/index.ua.js" /* webpackChunkName: "component---src-pages-index-ua-js" */),
  "component---src-pages-news-index-en-js": () => import("./../../../src/pages/news/index.en.js" /* webpackChunkName: "component---src-pages-news-index-en-js" */),
  "component---src-pages-news-index-ru-js": () => import("./../../../src/pages/news/index.ru.js" /* webpackChunkName: "component---src-pages-news-index-ru-js" */),
  "component---src-pages-news-index-ua-js": () => import("./../../../src/pages/news/index.ua.js" /* webpackChunkName: "component---src-pages-news-index-ua-js" */),
  "component---src-pages-projects-index-en-js": () => import("./../../../src/pages/projects/index.en.js" /* webpackChunkName: "component---src-pages-projects-index-en-js" */),
  "component---src-pages-projects-index-ru-js": () => import("./../../../src/pages/projects/index.ru.js" /* webpackChunkName: "component---src-pages-projects-index-ru-js" */),
  "component---src-pages-projects-index-ua-js": () => import("./../../../src/pages/projects/index.ua.js" /* webpackChunkName: "component---src-pages-projects-index-ua-js" */)
}

