import React from "react";
import { Skeleton } from "@mui/material";
import ContainerCustom from "./ContainerCustom";
import Flex from "./Flex";

export default function SkeletonLoading() {
  return (
    <>
      <Skeleton variant="rectangular" width="100%" height="3rem" />
      <ContainerCustom variant="main" mt="1.5rem">
        <Flex justifyContent="space-between">
          <Flex flexBasis="20%" flexDirection="column" gap="0.5rem">
            <Skeleton variant="rectangular" width="100%" height="3rem" />
            <Skeleton variant="rectangular" width="100%" height="3rem" />
            <Skeleton variant="rectangular" width="100%" height="3rem" />
            <Skeleton variant="rectangular" width="100%" height="3rem" />
            <Skeleton variant="rectangular" width="100%" height="3rem" />
          </Flex>
          <Flex flexBasis="70%" flexDirection="column" gap="1rem">
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width={250} height={250} />
            <Skeleton variant="rounded" width="100%" height={60} />
            <Skeleton variant="rounded" width="100%" height={120} />
            <Skeleton variant="circular" width={150} height={150} />
            <Skeleton variant="rounded" width="100%" height={40} />
          </Flex>
        </Flex>
      </ContainerCustom>
    </>
  );
}
