import React, { useContext } from "react";
import Box from "@mui/material/Box";
import MenuList from "./MenuList";
import { IsMobileContext } from "layouts";

function SidebarMenu({ lang, ...props }) {
  const { isMobile } = useContext(IsMobileContext);

  return (
    <Box
      sx={{
        display: isMobile ? "none" : "block",
        minWidth: "12.5rem",
        ...props,
      }}
    >
      <Box
        id="sidebar"
        sx={{
          position: "sticky",
          top: "3.5rem",
          transition: "transform 0.4s",
        }}
      >
        <MenuList lang={lang} />
      </Box>
    </Box>
  );
}

export default SidebarMenu;
