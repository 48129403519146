import React from "react";
import { Divider } from "@mui/material";
import { Code } from "components";

function hr() {
  return <Divider sx={{ marginTop: "1.25rem", marginBottom: "1rem" }} />;
}

function code(text) {
  return <Code>{text}</Code>;
}

export { hr, code };
