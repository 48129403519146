import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
// import { Box } from "@mui/material";
import {
  hyperlink,
  paragraph,
  heading_1,
  heading_2,
  heading_3,
  hr,
  code,
  embeddedAsset,
} from "./richTextComponents";

const richTextAssets = {};

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: paragraph,
    [BLOCKS.HEADING_1]: heading_1,
    [BLOCKS.HEADING_2]: heading_2,
    [BLOCKS.HEADING_3]: heading_3,
    [INLINES.HYPERLINK]: hyperlink,
    [BLOCKS.HR]: hr,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return embeddedAsset(node, richTextAssets);
    },
  },
  renderMark: {
    [MARKS.CODE]: code,
  },
};

const ContentfulRichText = ({ richText }) => {
  richText.references?.map((reference) => {
    const image_id = reference.contentful_id;
    return (richTextAssets[image_id] = {
      image: reference.gatsbyImageData,
      alt: reference.description,
      title: reference.title,
      file: reference.file,
    });
  });

  return <>{documentToReactComponents(JSON.parse(richText.raw), options)}</>;
};

export default ContentfulRichText;
