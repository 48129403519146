import * as React from "react";
import { useLocation } from "@reach/router";

export const LangContext = React.createContext();

export const LangContextProvider = ({ children }) => {
  // const [lang, setLang] = React.useState("en")

  const location = useLocation();
  // const previousPageUrl = location?.state?.referrer || "/"; // Default to home page if referrer is not available

  let lang = "en";
  if (location.pathname.includes("/ua/")) {
    lang = "ua";
  } else if (location.pathname.includes("/ru/")) {
    lang = "ru";
  }

  return <LangContext.Provider value={lang}>{children}</LangContext.Provider>;
};
