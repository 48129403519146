import React from "react";
import { getImage } from "gatsby-plugin-image";
import ImageWithPopup from "../ImageWithPopup";
import GifWithPopup from "../GifWithPopup";

export default function embeddedAsset(node, richTextAssets) {
  const id = node.data.target.sys.id;
  const asset = richTextAssets[id];

  if (asset) {
    // Check it it's GIF
    const gifImages = asset.image?.images.sources.filter(
      (img) => img.type === "image/gif"
    );
    if (gifImages && gifImages.length) {
      const { sizes, srcSet } = gifImages[0];
      return <GifWithPopup srcSet={srcSet} sizes={sizes} alt={asset.alt} />;
    }
    // Check if it's a video
    const isVideo = asset.file?.contentType === "video/mp4";
    if (isVideo) {
      const videoUrl = asset.file.url;
      return (
        <video width="100%" controls title={asset.title}>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    // Else render image
    const image = getImage(asset.image);
    if (image) {
      return <ImageWithPopup image={image} alt={asset.alt} />;
    }
  }

  return <></>;
}
