import React from "react";
import { Link } from "components";
import { Box } from "@mui/material";

function ServiceIcon({ service, title }) {
  return (
    <Box
      as="span"
      sx={{
        minWidth: "2.5rem",
        minHeight: "2.5rem",
        maxWidth: "2.5rem",
        maxHeight: "2.5rem",
        overflow: "hidden",
      }}
    >
      <img
        src={`https://static.tinaynox.com/images/services/${service}.png`}
        alt={title}
        style={{
          maxWidth: "inherit",
          maxHeight: "inherit",
          borderRadius: "1.25rem",
        }}
        title={title}
      />
    </Box>
  );
}

export default function ServiceLink({ uri, service, title }) {
  if (uri.includes("https://")) {
    // External link
    return (
      <a
        href={`${uri}/`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "underline", whiteSpace: "nowrap" }}
      >
        <ServiceIcon service={service} title={title} />
      </a>
    );
  }

  return (
    <Link
      to={`${uri}/`}
      rel="noopener noreferrer"
      style={{ textDecoration: "underline", whiteSpace: "nowrap" }}
    >
      <ServiceIcon service={service} title={title} />
    </Link>
  );
}
