import React, { useState } from "react";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GatsbyImage } from "gatsby-plugin-image";

function FullScreenImageDialog({ open, onClose, image, alt }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        style: { backgroundColor: "transparent", boxShadow: "none" },
      }}
    >
      <IconButton
        style={{
          position: "absolute",
          right: 10,
          top: 10,
          color: "white",
          zIndex: 1000,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        className="image-popup"
        style={{
          padding: "0",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/*<Box p="1rem">*/}
        <GatsbyImage
          image={image}
          alt={alt}
          style={{
            overflowY: "visible",
            height: "100%",
            //width: "100%",
            maxHeight: "90vh",
            maxWidth: "90vw",
          }}
        />
        {/*</Box>*/}
      </DialogContent>
    </Dialog>
  );
}

export default function ImageWithPopup({ image, alt, ...props }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box onClick={handleOpen}>
        <GatsbyImage
          image={image}
          alt={alt}
          style={{
            margin: "0.25rem 0 0.5rem",
            borderRadius: "0.25rem",
            cursor: "pointer",
            overflow: "hidden",
            ...props,
          }}
        />
      </Box>
      <FullScreenImageDialog
        open={open}
        onClose={handleClose}
        image={image}
        alt={alt}
      />
    </>
  );
}
