import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import { Container } from "components";
import MenuDrawer from "./MenuDrawer";
import LanguageSelector from "./LanguageSelector";
import ModeButton from "./ModeButton";
import ViewButton from "./ViewButton";

export default function Header({ lang }) {
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const scrollingDown = currentScrollPos > prevScrollPos;
      const topOfScreen = currentScrollPos < 20;

      let hideHeader = scrollingDown && !topOfScreen;

      if (hideHeader) {
        // Scrolling down, hide the header
        document.getElementById("header").classList.add("hide-header");
        document.getElementById("sidebar").classList.add("lift-sidebar");
      } else {
        // Scrolling up, show the header
        document.getElementById("header").classList.remove("hide-header");
        document.getElementById("sidebar").classList.remove("lift-sidebar");
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <AppBar
      id="header"
      sx={{
        position: "fixed",
        top: 0,
        transition: "transform 0.4s",
      }}
    >
      <Toolbar variant="dense">
        <Container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" gap="1rem">
            <MenuDrawer lang={lang} />
            <LanguageSelector lang={lang} />
          </Box>
          <Box display="flex">
            <ViewButton />
            <ModeButton />
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
