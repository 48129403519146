// import { grey, amber, deepOrange } from "@mui/material/colors";

export const getDesignTokens = (mode) => ({
  mode,
  ...(mode === "light"
    ? {
        // palette values for light mode
        // primary: amber,
        // divider: amber[200],
        // text: {
        //   primary: grey[900],
        //   secondary: grey[800],
        // },
      }
    : {
        // palette values for dark mode
        // primary: deepOrange,
        // divider: deepOrange[700],
        // background: {
        //   default: deepOrange[900],
        //   paper: deepOrange[900],
        // },
        // text: {
        //   primary: "#fff",
        //   secondary: grey[500],
        // },
      }),
});
