import React, { useContext } from "react";
import { IconButton } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { ColorModeContext } from "layouts";

export default function ModeButton() {
  const { mode, colorMode } = useContext(ColorModeContext);
  const title = mode === "dark" ? "Light mode" : "Dark mode";

  return (
    <IconButton
      sx={{ ml: 1 }}
      onClick={colorMode?.toggleColorMode}
      color="inherit"
      title={title}
    >
      {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
}
