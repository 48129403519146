import React, { useState } from "react";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function FullScreenImageDialog({ open, onClose, srcSet, sizes, alt }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      //  fullWidth
      PaperProps={{
        style: { backgroundColor: "transparent", boxShadow: "none" },
      }}
    >
      <IconButton
        style={{
          position: "absolute",
          right: 10,
          top: 10,
          color: "white",
          zIndex: 1000,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        style={{ padding: 0, display: "flex", justifyContent: "center" }}
      >
        <picture>
          <source srcset={srcSet} sizes={sizes} type="image/gif" />
          <img
            src={srcSet}
            alt={alt}
            style={{
              maxHeight: "90vh",
              maxWidth: "90vw",
            }}
          />
        </picture>
      </DialogContent>
    </Dialog>
  );
}

export default function GifWithPopup({ srcSet, sizes, alt, ...props }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          width: "inherit",
          maxWidth: "100%",
          // minHeight: "40vh",
        }}
      >
        <picture>
          <source srcset={srcSet} sizes={sizes} type="image/gif" />
          <img
            src={srcSet}
            alt={alt}
            style={{
              width: "auto",
              maxWidth: "100%",
              height: "100%",
              margin: "0.25rem 0 1rem",
              borderRadius: "0.25rem",
              overflow: "hidden",
              cursor: "pointer",
              ...props,
            }}
          />
        </picture>
      </Box>
      <FullScreenImageDialog
        open={open}
        onClose={handleClose}
        srcSet={srcSet}
        sizes={sizes}
        alt={alt}
      />
    </>
  );
}
