import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
// import { localization } from "static";

const Seo = ({ title, page, lang }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <link
        rel="icon"
        href="https://static.tinaynox.com/images/favicon/favicon-32x32.png"
      />
      <title>
        {title} | {data.site.siteMetadata.title}
      </title>
    </>
  );
};

export default Seo;
