import React, { useContext } from "react";
import "styles/styles.css";
import Box from "@mui/material/Box";
import { Header, Footer, SidebarMenu } from "general";
import { Container, SkeletonLoading } from "components";
import { ColorModeContext, LangContext, IsMobileContext } from "layouts";

export const Layout = ({ children, ...props }) => {
  const { mode } = useContext(ColorModeContext);
  const { isMobile } = useContext(IsMobileContext);
  const lang = useContext(LangContext);

  if (typeof isMobile === "undefined") {
    return <SkeletonLoading />;
  }

  return (
    <Box
      sx={{
        minHeight: "99.9vh",
        display: "flex",
        flexDirection: "column",
      }}
      key={mode}
      {...props}
    >
      <Header lang={lang} key={isMobile} />
      <Container variant="main" mt="3rem">
        <Box
          sx={{
            display: "flex",
            gap: { xs: "1rem", md: "3rem" },
            paddingBottom: "2rem",
          }}
          as="main"
          key={isMobile}
        >
          <SidebarMenu lang={lang} marginTop="1rem" />
          <Box sx={{ width: "100%", mt: "1.25rem" }}>{children}</Box>
        </Box>
      </Container>
      <Footer lang={lang} />
    </Box>
  );
};
