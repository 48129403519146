import React, { useContext } from "react";
import { Box, Typography, Divider } from "@mui/material";
import { Container, Link } from "components";
import { useTheme } from "@mui/material/styles";
import { IsMobileContext } from "layouts";

export default function Footer({ lang, ...props }) {
  const theme = useTheme();
  const borderColor = theme.palette.primary.light;
  const currentYear = new Date().getFullYear();
  const { isMobile } = useContext(IsMobileContext);

  return (
    <Box
      variant="footer"
      as="footer"
      sx={{
        marginTop: "auto",
        alignItems: "center",
        minHeight: "2.5rem",
        ...props,
      }}
    >
      <Divider sx={{ borderColor: borderColor, opacity: "15%" }} />
      <Container
        m={isMobile ? "0.5rem 0" : ""}
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="center"
        gap="0.5rem"
        minHeight="2.5rem"
      >
        <Typography sx={{ fontStyle: "italic" }}>
          © Copyright {currentYear} — <b>Dmytro Nosov</b>
        </Typography>
        <Typography sx={{  fontStyle: "italic" }}>
          Powered by{" "}
          <Link
            to="https://www.linkedin.com/in/volodymyr-hamalii/"
            style={{ textDecoration: "underline" }}
            target="_blank"
          >
            Volodymyr Hamalii
          </Link>
        </Typography>
      </Container>
    </Box>
  );
}
