import { grey } from "@mui/material/colors";
import darkScrollbar from "@mui/material/darkScrollbar";

export const getComponents = (mode) => {
  const similarComponents = {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          ...darkScrollbar(
            mode === "light"
              ? {
                  track: grey[200],
                  thumb: grey[400],
                  active: grey[400],
                }
              : undefined
          ),
          //scrollbarWidth for Firefox
          scrollbarWidth: "thin",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h2",
          subtitle2: "h2",
          body1: "span",
          body2: "span",
        },
        //textAlign: "justify",
        fontSize: "0.85rem",
      },
      variants: [
        {
          props: { variant: "h1" },
          style: (props) => {
            const { breakpoints } = props.theme;
            return {
              [breakpoints.up("xs")]: {
                fontSize: "1.75rem",
                marginTop: "0.5rem",
                marginBottom: "1rem",
                textAlign: "left!important",
              },
              [breakpoints.up("md")]: {
                fontSize: "2rem",
              },
            };
          },
        },
        {
          props: { variant: "h2" },
          style: (props) => {
            const { breakpoints } = props.theme;
            return {
              [breakpoints.up("xs")]: {
                fontSize: "1.5rem",
                marginTop: "0.25rem",
                marginBottom: "1rem",
                textAlign: "left!important",
              },
              [breakpoints.up("md")]: {
                fontSize: "1.75rem",
              },
            };
          },
        },
        {
          props: { variant: "h3" },
          style: (props) => {
            const { breakpoints } = props.theme;
            return {
              [breakpoints.up("xs")]: {
                fontSize: "1.25rem",
                marginTop: "0.25rem",
                marginBottom: "0.75rem",
                textAlign: "left!important",
              },
              [breakpoints.up("md")]: {
                fontSize: "1.5rem",
              },
            };
          },
        },
        {
          props: { variant: "h4" },
          style: (props) => {
            const { breakpoints } = props.theme;
            return {
              [breakpoints.up("xs")]: {
                fontSize: "1.1rem",
                textAlign: "left",
              },
              [breakpoints.up("md")]: {
                fontSize: "1rem",
              },
            };
          },
        },
        {
          props: { variant: "h5" },
          style: {
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "h6" },
          style: {
            fontSize: "1rem",
          },
        },
      ],
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [`@media (min-width:1300px)`]: {
            minWidth: "950px",
          },
        },
      },
    },
  };

  return mode === "light"
    ? {
        ...similarComponents,
        // text: {
        //   primary: "#fff",
        //   secondary: [500],
        // },
        // Light mode styles
        MuiButton: {
          // styleOverrides: {
          //   color: "black",
          // },
          variants: [
            {
              props: { variant: "menu" },
              style: {
                color: "white",
              },
            },
          ],
        },
      }
    : {
        // Dark mode styles
        // MuiButton: {
        //   // styleOverrides: {
        //     // Add your general styles here
        //     // color: "red",
        //     // backgroundColor: "green",
        //   // },
        //   variants: [
        //     {
        //       props: { variant: "menu" },
        //       style: {
        //         // color: "green",
        //       },
        //     },
        //     // {
        //     //   props: { variant: "outlined" },
        //     //   style: {
        //     //     color: "green",
        //     //   },
        //     // },
        //   ],
        // },
        ...similarComponents,
      };
};

// export default { ...components };
