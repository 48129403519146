import React from "react";
import slugify from "@sindresorhus/slugify";
import { Typography } from "@mui/material";

function heading_1(node, children) {
  if (typeof children[0] == "string") {
    return (
      <Typography
        as="h2"
        variant="h2"
        id={slugify(children[0].toLowerCase())}
        sx={{ marginBottom: "1rem", marginTop: "0.5rem" }}
      >
        {children}
      </Typography>
    );
  }
  return (
    <Typography
      as="h2"
      variant="h2"
      id={slugify(children[0].props.children.toLowerCase())}
      sx={{ marginBottom: "1rem", marginTop: "0.5rem" }}
    >
      {children}
    </Typography>
  );
}

function heading_2(node, children) {
  if (typeof children[0] == "string") {
    return (
      <Typography
        as="h3"
        variant="h3"
        id={slugify(children[0].toLowerCase())}
        sx={{ m: "0.75rem 0" }}
      >
        {children}
      </Typography>
    );
  }
  return (
    <Typography
      as="h3"
      variant="h3"
      id={slugify(children[0].props.children.toLowerCase())}
      sx={{ m: "0.75rem 0" }}
    >
      {children}
    </Typography>
  );
}

function heading_3(node, children) {
  if (typeof children[0] == "string") {
    return (
      <Typography
        as="h4"
        variant="h4"
        id={slugify(children[0].toLowerCase())}
        sx={{ m: "0.5rem 0" }}
      >
        {children}
      </Typography>
    );
  }
  return (
    <Typography
      as="h4"
      variant="h4"
      id={slugify(children[0].props.children.toLowerCase())}
      sx={{ m: "0.5rem 0" }}
    >
      {children}
    </Typography>
  );
}

export { heading_1, heading_2, heading_3 };
