import * as React from "react";
import Container from "@mui/material/Container";

export default function ContainerCustom({ children, ...props }) {
  return (
    <Container sx={{ maxWidth: { xs: "md", xl: "lg" }, ...props }}>
      {children}
    </Container>
  );
}
