import * as React from "react";
import { Typography, Box, Button } from "@mui/material";
import Link from "./Link";
import { localization } from "static";

function MoreDetailsLink({ text, lang, path, ...props }) {
  if (!text) {
    if (!lang) {
      lang = "en";
    }
    text = localization.projects.readMore[lang].toUpperCase();
  }

  return (
    <Box mt="1.5rem" maxWidth="9rem" sx={{ ...props }}>
      <Link to={path} width="100%">
        <Button
          variant="contained"
          sx={{
            width: "100%",
            height: "1.75rem",
            pt: "0.15rem",
            display: "block",
            textTransform: "initial",
          }}
        >
          <Typography>{text.toUpperCase()}</Typography>
        </Button>
      </Link>
    </Box>
  );
}

export default MoreDetailsLink;
