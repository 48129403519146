import React from "react";
import YouTube from "react-youtube";
import { Box } from "@mui/material";

function getYouTubeVideoId(url) {
  // Extract the video ID from the URL
  const match = url.match(/[?&]v=([^&]+)/);
  return match && match[1];
}

const YouTubeVideo = ({ url }) => {
  const videoId = getYouTubeVideoId(url);

  const opts = {
    width: "100%",
    heigth: "auto",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <Box pt="1rem" width="100%">
      <YouTube videoId={videoId} opts={opts} />
    </Box>
  );
};

export default YouTubeVideo;
