import * as React from "react";
import HomeIcon from "@mui/icons-material/Home";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import ContactlessIcon from "@mui/icons-material/Contactless";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ArticleIcon from "@mui/icons-material/Article";
import StarIcon from "@mui/icons-material/Star";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { localization } from "static";
import { Link } from "components";
import { useLocation } from "@reach/router";

function getIcon(itemType) {
  switch (itemType) {
    case "home":
      return <HomeIcon />;

    case "about":
      return <SelfImprovementIcon />;

    case "news":
      return <ContactlessIcon />;

    case "projects":
      return <AccountTreeIcon />;

    case "articles":
      return <ArticleIcon />;

    case "documents":
      return <ContentPasteIcon />;

    case "contacts":
      return <AlternateEmailIcon />;

    default:
      return <StarIcon />;
  }
}

function MenuList({ lang, ...props }) {
  const location = useLocation();
  const menuKeys = Object.entries(localization.menu[lang]);
  return (
    <List sx={{ ...props }}>
      {menuKeys.map(([key, value]) => {
        let link = key !== "home" ? `/${key}` : `/`;
        if (lang !== "en") {
          link = `/${lang}${link}`;
        }

        const path = location.pathname;
        const homePaths = ["/", "/ru/", "/ua/"];

        const isSeleted =
          path.includes(`/${key}/`) ||
          (homePaths.includes(path) && key === "home");

        return (
          <Link key={key} to={link}>
            <ListItem disablePadding>
              <ListItemButton selected={isSeleted}>
                <ListItemIcon>{getIcon(key)}</ListItemIcon>
                <ListItemText primary={value} />
              </ListItemButton>
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
}

export default MenuList;
